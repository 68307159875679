<template>
    <div class="audio">
        <div class="tabHeader">
            <div class="tab"><button v-for="tab in tabs" :key="tab.id" @click="selectTab(tab.id)"
                    :class="{ active: activeTab === tab.id }">
                    {{ tab.title }}
                </button>
            </div>
            <div class="found"><router-link to="/SoundCloning"><button>克隆声音</button></router-link></div>
        </div>
        <!-- 我的声音 -->
        <div class="digitalCloning" v-if="activeTab === 'myVoice'">
            <!-- <div class="foundCloning" v-if="myVoiceList == 0">
                <div class="item">
                    <div class="avatar"><img src="../assets/cVoice.png" alt=""></div>
                    <div class="textArea">
                        <div class="title">极速克隆你的专属声音</div>
                        <div class="text">上传音频自动克隆声音</div>
                    </div>
                </div>
            </div> -->
            <div class="myVoice" v-if="myVoiceList !== 0">
                <myVoice ref="child" @myIndex="myIndex" @endAudio="endAudio" :list="myVoiceList" />


                <div class="paging" v-if="myTotal > 15"> 
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="myPage"
                        :page-size="15" :total="myTotal" :page-sizes="[15]" @current-change="myPageChange" />
                </div>
            </div>
        </div>
        <!-- 公共声音 -->
        <div class="comVoice" v-if="activeTab === 'comVoice'">
            <publicVoice ref="child" @comIndex="comIndex" @endAudio="endAudio" :list="commonalityList" />

            <div class="paging" v-if="comTotal > 15">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="comPage"
                    :page-size="15" :total="comTotal" :page-sizes="[15]" @current-change="comPageChange" />
            </div>
        </div>

    </div>
</template>
<script>
import myVoice from '../../components/voiceModule/myVoice.vue'
import publicVoice from '../../components/voiceModule/commonalityVoice.vue'
import { getCommonsoundModel, getMysoundModel } from '../../api/api'

export default {
    components: {
        myVoice,
        publicVoice
    },
    data() {
        return {
            activeTab: 'myVoice',
            tabs: [
                { id: 'myVoice', title: '我的声音' },
                { id: 'comVoice', title: '公共声音' }
            ],
            myVoiceList: [],
            mIndex: '',
            commonalityList: [],
            cIndex: '',


            myTotal: '',//我的声音总数
            myPage: 1,//我的声音页数

            comTotal: '',//公共声音总数
            comPage: 1,//公共声音页数
            fullscreenLoading: false//加载

        }
    },
    provide() {
        return {
            getMysoundModel: this.getMysoundModel
        };
    },
   
    methods: {
        selectTab(tabId) {
            this.activeTab = tabId;
            this.$refs.child.endMyAudio()
            if (this.mIndex !== '') {
                this.myVoiceList[this.mIndex].show = false
                this.mIndex = ''
            }
            if (this.cIndex !== '') {
                // console.log(this.cIndex)
                this.commonalityList[this.cIndex].show = false
                this.cIndex = ''
            }
        },
        myIndex(index) {
            this.mIndex = index
        },
        comIndex(index) {
            this.cIndex = index
        },
        // 切换tab把所有音频的show设置为false
        endAudio(index) {
            for (let i = 0; i < this.myVoiceList.length; i++) {
                this.myVoiceList[i].show = false
            }
            for (let i = 0; i < this.commonalityList.length; i++) {
                this.commonalityList[i].show = false
            }
        },
        // 我的声音翻页
        myPageChange(e) {
            this.myPage = e
            this.getMysoundModel()
        },
        // 公共声音翻页
        comPageChange(e) {
            this.comPage = e
            this.getCommonsoundModel()
        },
        // 获取公共声音模型
        getCommonsoundModel() {
            let params = {
                page: this.comPage,
                page_size: 15
            }
            getCommonsoundModel().then(res => {
                if (res.data.code == 10000) {
                    this.commonalityList = []
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].show = false
                        this.commonalityList.push(list[i])
                        this.comTotal = res.data.total

                    }
                }
            })
        },
        // 获取我的声音模型
        getMysoundModel() {
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            let data = {
                page: this.myPage,
                page_size: 15
            }
            getMysoundModel(data).then(res => {
                if (res.data.code == 10000) {
                    let list = res.data.data
                    this.myVoiceList = []
                    for (let i = 0; i < list.length; i++) {
                        list[i].show = false
                        this.myVoiceList.push(list[i])
                        this.myTotal = res.data.total
                    }
                }
                loading.close();
            })
        }
    },
    created: function () {
        // 获取公共声音模型
        this.getCommonsoundModel()
        if(localStorage.getItem('token') == null) return
        this.getMysoundModel()
    },
}
</script>
<style lang="less" scoped>
.audio::-webkit-scrollbar {
    display: none
}

.audio {
    width: 1540px;
    overflow-y: auto;
    overflow-x: auto;

    height: 800px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;

    .tabHeader {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        .tab {
            button {
                background: none;
                border: none;
                padding: 15px 0;
                font-size: 26px;
                margin-right: 30px;
                cursor: pointer;
                transition: background-color 0.3s;
                border-bottom: 2px solid transparent;
                white-space: nowrap;
                color: #939393;
            }

            button.active {
                font-weight: bold;
                border-bottom: 2px solid #000;
                color: #000;
            }
        }

        .found {
            button {
                cursor: pointer;
                width: 159px;
                height: 46px;
                line-height: 46px;
                background: none;
                border: none;
                color: #fff;
                text-align: center;
                font-size: 16px;
                background-color: #4589F1;
                border-radius: 10px;
            }
        }
    }

    .digitalCloning {
        .foundCloning {
            margin-top: 20px;

            .item {
                display: flex;
                border: 1px solid #CCCACA;
                border-radius: 15px;
                padding: 0 10px;
                margin-right: 20px;
                width: 436px;
                height: 102px;

                .avatar {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 128px;
                        height: 128px;
                    }
                }

                .textArea {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        font-size: 24px;
                        line-height: 50px;
                    }

                    .text {
                        font-size: 16px;
                        color: #656365;
                    }
                }
            }
        }
    }
}

.myVoice {
    position: relative;

    .paging {
        position: sticky;
        margin-top: 30px;
        text-align: center;
    }
}

.comVoice {
    position: relative;

    .paging {
        position: sticky;
        margin-top: 30px;
        text-align: center;
    }
}
</style>
