<template>
    <div class="playVideo">

            <div class="video"><video v-if="videoShow" loop autoplay="autoplay" controls :src="item.video_url || item.source_url"></video>
            </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            default: {}
        },
        videoShow: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.playVideo {
    .video {
        display: flex;
        justify-content: center;

        video {
            border-radius: 20px;
            width: 350px;
        }
    }
}
</style>