<template>
    <div class="imageModule">
        <div class="item">

           

                <div class="back" v-if="item.progress == 2 && item.cover_url !==''"
                    :style="{ backgroundImage: 'url(' + item.cover_url + ')', backgroundSize: '100%' }"></div>

                <div class="back" v-if="item.progress !== 2"
                    :style="{ background: '#CECECE', backgroundSize: '100%' }">
                </div>

                <div class="miImg" v-if="item.progress == 2 && item.cover_url !==''">
                    <div class="item_img">
                        <img class="item_img_ab" v-if="item.progress == 2" :src="item.cover_url" alt="">
                        <div class="item_img_ab" v-if="item.progress !==2"
                            :style="{ background: '#A5A5A5' }">
                        </div>
                        <div class="Play_back" v-if="item.video_url"><img @click='playVideo(item)'
                                src="../../src/assets/bofang2.png" alt="">
                        </div>
                    </div>
                </div>

           
                <div class="video_item" v-if="item.progress == 2 && item.cover_url ==''">
                    <video class="video" :src="item.video_url"></video>
                    <div class="img" v-if="item.video_url"><img @click='playVideo(item)'
                            src="../../src/assets/bofang2.png" alt=""></div>
                </div>
           

            <div class="actionField" v-if="controlsIs">
                <div class="generate_download">
                    <div class="generate" v-if="vTtpe" @click="craft(item)">V{{ item.line_id }}生成</div>
                    <div class="download" v-if="downloadShow" @click="downloadd(item)">下载</div>
                </div>
                <el-popover popper-class="popover" placement="top" width="10" trigger="hover">
                    <div class="delete" @click="deleteItem()">删除</div>
                    <div class="refresh" @click="refresh()">刷新</div>
                    <div class="more" slot="reference">···</div>
                </el-popover>
            </div>
            <div class="name">
                <div class="text" v-if="controlsIs && item.progress == 2">{{ item.name }}</div>
                <div class="text" v-if="controlsIs && item.progress == 1">合成中</div>
                <div class="text" v-if="controlsIs && item.progress == 3">合成失败</div>


                <div class="img" @click="editName(index)" v-if="controlsIs && item.progress == 2"><img
                        src="../../src/assets/zuopin.png" alt=""></div>
                <div class="CraftingImg" v-if="controlsIs && item.progress == 1"><img
                        src="../../src/assets/hechengzhong.gif" alt=""></div>
            </div>

        </div>
    </div>
</template>
<script>
import { deleteMyWorkVideo, editMyWorkVideo, refreshMyWork } from '../../api/api'

import download from "downloadjs"

export default {
    inject: ['getMyImageList'],
    props: {
        item: {
            type: Object,
            default: {}
        },
        index: {
            type: Number,
            default: 0
        },
        newName: {
            type: String,
            default: ''
        },
        controlsIs: {
            type: Boolean,
            default: null
        },
        downloadShow: {
            type: Boolean,
            default: null
        },
        vTtpe: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            yes: true,
            tiem: null,
            fullscreenLoading: false//加载

        }
    },
    methods: {
        craft(item) {
            if (item.progress == 1) {
                this.$message({
                    message: '视频还在合成中，请稍后再试',
                    type: 'warning'
                });
                return
            }
            this.$router.push({ path: "/Home/VideoTable" });
        },
        // 播放视频
        playVideo(item) {
            this.$emit('videoItem', item)
        },

        // 下载函数
        // daonload(url, name) {
        //     fetch(url)
        //         .then(res => res.blob())
        //         .then(blob => {
        //             const a = document.createElement("a");
        //             const objectUrl = window.URL.createObjectURL(blob);
        //             a.download = name;
        //             a.href = objectUrl;
        //             a.click();
        //             window.URL.revokeObjectURL(objectUrl);
        //             a.remove();
        //         })
        // },





        // 下载
        downloadd(item) {
            if (item.progress == 1) {
                this.$message({
                    message: '视频还在合成中，请稍后再试',
                    type: 'warning'
                });
                return
            }
            if (item.video_url) {
                download(item.video_url);
                // console.log(item.video_url)
                // var a = document.createElement('a') // 创建一个<a></a>标签
                // a.href = item.video_url         //重点（如测试发现下载文件不存在/找不到，检查路径）
                // a.download = item.name         // 设置下载文件文件名
                // a.style.display = 'none'           // 隐藏a标签
                // document.body.appendChild(a)       // 将a标签追加到文档对象中
                // a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                // a.remove()                        // 一次性的，用完就删除a标签

            }
        },
        // 删除
        deleteItem() {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteMyWorkVideo()
            }).catch(() => {
            });
        },
        // 刷新
        refresh() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            that.time = setTimeout(function () {
                let params = {
                    id: that.item.id
                }
                // console.log(params)
                refreshMyWork(params).then(res => {
                    loading.close();
                    if(!res) return
                    if (res.data.code == 10000) {
                        // that.item = res.data.data
                        let data = JSON.stringify(res.data.data)
                        that.$emit('refreshItem', data)

                        that.$message({
                            message: '刷新成功',
                            type: 'success'
                        });
                    }
                })
                that.time = null;
            }, 1000)
        },
        // 修改名称
        editName() {
            this.$emit('editName', this.index)
        },
        // 删除我的作品
        deleteMyWorkVideo() {
            let data = {
                id: this.item.id
            }
            deleteMyWorkVideo(data).then(res => {
                console.log(res)
                if (res.data.code == 10000) {
                    this.$emit('deleteItem', this.index)
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getMyImageList()
                }
            })
        }
    }
}
</script>
<style lang="less">
.popover {
    .refresh {
        text-align: center;
        cursor: pointer;
        /*悬停变小手的属性*/
        line-height: 30px;
    }

    .delete {
        text-align: center;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        /*悬停变小手的属性*/
        line-height: 30px;
    }
}
</style>
<style lang="less" scoped>
.imageModule {
    .item {
        position: relative;
        width: 322px;

        .back {
            width: 322px;
            height: 181px;
            -webkit-filter: blur(2px);
            -moz-filter: blur(2px);
            -o-filter: blur(2px);
            -ms-filter: blur(2px);
            filter: blur(2px);
        }

        .miImg {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -50.5px;

            .item_img:hover {
                cursor: pointer;

                /*悬停变小手的属性*/
                .Play_back {
                    img {
                        display: block;
                    }
                }
            }

            .item_img {
                position: relative;

                .item_img_ab {
                    margin-top: 1px;
                    display: block;
                    width: 101px;
                    height: 179px;
                }

                .Play_back {

                    position: absolute;
                    top: 70px;
                    left: 28.5px;

                    img {
                        display: none;
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }

        .actionField {
            height: 43px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);

            .generate_download {
                display: flex;

                div {
                    margin-top: 7.5px;
                    height: 18px;
                    line-height: 18px;
                    padding: 5px 10px;
                    background-color: #3E7AFE;
                    color: #fff;
                    margin-right: 10px;
                    border-radius: 50px;
                    font-size: 18px;
                    cursor: pointer;
                    /*悬停变小手的属性*/

                }
            }

            .more {
                margin-top: 9px;
                font-size: 18px;
                height: 15px;
                line-height: 15px;
                border-radius: 5px;
                padding: 5px;
                background-color: #fff;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                cursor: pointer;
                /*悬停变小手的属性*/
            }
        }

        .name {
            display: flex;
            justify-content: center;
            line-height: 18px;
            margin-top: 10px;
            cursor: pointer;

            .text {
                font-size: 18px;
                color: #3D3D3D;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            img {
                margin-left: 10px;
                width: 18px;
                height: 18px;

            }

            .CraftingImg {
                img {
                    height: 20px;
                    width: 30px;
                }
            }
        }
    }

}

.video_item {
    background-color: #000;
    height: 185px;
    position: relative;

    .video {
        width: 322px;
        height: 185px;
    }

    .img {
        position: absolute;
        left: 140px;
        top: 70px;
        display: none;
    }
}

.video_item:hover {
    .img {
        display: block;
        cursor: pointer;
    }

}
</style>