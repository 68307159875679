<template>
    <!-- 文本驱动合成音频弹出框 -->
    <div class="audioPopup">
        <!-- 选择我的——公模 -->
        <div class="selectionModel">
            <div class="tabHeader">
                <div v-for="tab in tabs" :key="tab.id" @click="selectTab(tab.id)"
                    :class="{ active: activeTab === tab.id }">
                    {{ tab.title }}
                </div>
            </div>
            <div class="audioList">
                <div class="item">
                    <audioMini ref="child" :list="list" @audioId="audioId" />
                </div>
            </div>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination layout="prev, pager, next" :current-page.sync="page" :page-size="8" :total="total"
                    @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
        <div class="more">
            <!-- 文本框 -->
            <div class="textarea">
                <!-- <textarea name="" id="" v-model="setData.content"></textarea> -->
                <el-input type="textarea" maxlength="1000" placeholder="请输入内容" v-model="setData.content"
                    show-word-limit>
                </el-input>
                <div class="interposition">
                    <div style="display: flex;">
                        <el-tooltip content="AI创作" placement="top">
                            <div class="interposition_box AiCreation">
                                <div> <img @click="AiCreationShow = true" src="../../assets/AiCzMin.png" alt=""></div>
                            </div>
                        </el-tooltip>

                        <el-tooltip content="插入停顿" placement="top">
                            <div class="interposition_box standstill">
                                <div> <img src="../../assets/tingdun.png" alt=""></div>
                            </div>
                        </el-tooltip>

                        <el-tooltip content="插入情感" placement="top">
                            <div class="interposition_box emotion">
                                <div><img src="../../assets/qinggan.png" alt=""></div>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>

            <!-- 语速-音量-音调 -->
            <div class="pace_volume_tone">
                <div class="pace_box">
                    <div class="pace">
                        <el-slider :max="30" v-model="setData.speed_ratio" vertical height="130px">
                        </el-slider>
                        <div class="title">
                            <div class="top">语速</div>
                            <div class="bottom">{{ setData.speed_ratio }}</div>
                        </div>
                    </div>
                    <div class="pace">
                        <el-slider :max="30" v-model="setData.volume_ratio" vertical height="130px">
                        </el-slider>
                        <div class="title">
                            <div class="top">音量</div>
                            <div class="bottom">{{ setData.volume_ratio }}</div>
                        </div>
                    </div>
                    <div class="pace">
                        <el-slider :max="30" v-model="setData.pitch_ratio" vertical height="130px">
                        </el-slider>
                        <div class="title">
                            <div class="top">音调</div>
                            <div class="bottom">{{ setData.pitch_ratio }}</div>
                        </div>
                    </div>
                </div>
                <!-- 选择文案模板 -->
                <div class="copyTemplate">
                    <div class="button" @click="selectTemplate">选择文案模板</div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer" style="margin-top: 20px; display: flex;justify-content: right;">
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>



        <el-dialog title="AI创作"  append-to-body custom-class="AiCreationPop" :visible.sync="AiCreationShow"
            width="500px">
            <el-input v-model="aiValue" placeholder="请输入文案主题"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AiCreationShow = false">取 消</el-button>
                <el-button type="primary" @click="generate">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import audioMini from '../../../components/audioMini.vue'
import { getMysoundModel, getCommonsoundModel, textToAudio, aiCreate } from '../../../api/api'
export default {
    components: {
        audioMini
    },
    props: {
        audioPopShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeTab: 'mine',//选中tab的ID
            tabs: [{ id: 'mine', title: '我的' }, { id: 'com', title: '公共' },],

            list: [],//音频列表
            page: 1,//页数
            total: 0,//音频总数
            // 提交的数据
            setData: {
                model_id: '',// 组件传过来的选中的音频的id
                content: '',
                pitch_ratio: 10,
                speed_ratio: 10,
                volume_ratio: 10,
            },

            aiValue: '',//文案主题
            AiCreationShow: false,//ai创作框

            time: null,
        }
    },
    methods: {
        // 选项卡
        selectTab(id) {
            this.activeTab = id
            // 切换后关闭子组件的音频播放
            this.$refs.child.pause()
            // 切换后清除选中的声音模型
            this.setData.model_id = ''
            this.$refs.child.itemIndex = -1
            this.page = 1
            this.list = []

            if (this.activeTab == 'mine') {//我的列表
                this.getMysoundModel()
            }
            if (this.activeTab == 'com') {//我的列表
                this.getCommonsoundModel()
            }
        },
        // 组件传过来的选中的音频的id
        audioId(e) {
            this.setData.model_id = e
        },
        // 分页
        handlePageChange(page) {
            this.page = page
            this.list = []
            if (this.activeTab == 'mine') {//我的列表
                this.getMysoundModel()
            }
            if (this.activeTab == 'com') {//我的列表
                this.getCommonsoundModel()
            }
        },
        selectTemplate() {
            this.$message({
                message: '功能开发中，敬请期待',
                type: 'success'
            });
        },
        // 提交
        submit: function () {
            let that = this
            if (!that.setData.model_id) return that.$message({ message: '请选择声音模型', type: 'warning' });
            if (!that.setData.content) return that.$message({ message: '请输入需要合成的话术', type: 'warning' });

            if (that.time) {
                clearTimeout(that.time)
            }
            const loading = that.$loading({
                lock: true,
                text: '上传中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            that.time = setTimeout(function () {
                let data = {
                    ...that.setData
                }
                textToAudio(data).then(res => {
                    loading.close();
                    if(!res) return
                    if (res.data.code == 10000) {
                        that.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        that.setData.model_id = ''
                        that.$refs.child.itemIndex = -1
                        that.setData.content = ''
                        that.setData.pitch_ratio = 10
                        that.setData.speed_ratio = 10
                        that.setData.volume_ratio = 10
                        that.$emit('popup', false)
                        loading.close();
                    }
                })
                that.time = null;
            }, 1000)
        },

        // AI创作文案

        // 防抖
        generate() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
                let data = {
                    content: that.aiValue
                }
                aiCreate(data).then(res => {
                    that.AiCreationShow = false
                    loading.close();
                    if (!res) return
                    if (res.data.code == 10000) {
                        that.setData.content = res.data.data[0]
                        that.drawer = true
                    }
                })
                that.time = null;
            }, 1000)
        },

        // 获取我的声音模型
        getMysoundModel() {
            let params = {
                page: this.page,
                page_size: 8,
            }
            getMysoundModel(params).then(res => {
                if (res.data.code == 10000) {
                    this.list = []
                    this.total = res.data.total
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].show = false
                        this.list.push(list[i])
                    }
                }
            })
        },
        // 获取公共声音模型
        getCommonsoundModel() {
            let params = {
                page: this.page,
                page_size: 8,
            }
            getCommonsoundModel(params).then(res => {
                if (res.data.code == 10000) {
                    this.list = []
                    this.total = res.data.total
                    let list = res.data.data
                    for (let i = 0; i < list.length; i++) {
                        list[i].show = false
                        this.list.push(list[i])
                    }
                }
            })
        }
    },
    watch: {
        audioPopShow(newValue, oldValue) {
            if (newValue == true) {
                this.$refs.child.pause()
            }
        }
    },
    created() {
        this.getMysoundModel()//只获取我的声音列表
        window.myData = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
    }
}
</script>
<style lang="less" scoped>
.selectionModel {
    .tabHeader {
        display: flex;
        background-color: #fff;
    }

    .audioList {
        display: flex;
        flex-wrap: wrap;
        min-height: 145px;

        .item {
            margin-top: 10px;
        }
    }

    .tabHeader div {
        width: 80px;
        height: 38px;
        border: 1px solid #ccc;
        font-size: 20px;
        text-align: center;
        line-height: 38px;
        margin-right: 10px;
        cursor: pointer;
        /*悬停变小手的属性*/
    }

    .tabHeader div.active {
        border: none;
        background-color: #279CEF;
        color: #fff;
    }

    .paging {
        display: flex;
        justify-content: center;
    }
}

.more {
    display: flex;

    .pace_volume_tone {
        margin-left: 30px;

        .pace_box {
            display: flex;
            width: 247px;
            height: 171px;
            border-radius: 5px;
            border: 1px solid #CCCACA;
            padding-top: 20px;
            padding-left: 7.5px;
        }

        .pace {
            text-align: center;
            margin-right: 20px;

            .el-slider {
                display: flex;
                justify-content: center;
            }

            .title {
                // margin-top: 10px;
                // font-size: 16px;
                display: flex;
                line-height: 25px;

                .top {
                    font-size: 13px;
                    white-space: nowrap; //强制不换行

                }

                .bottom {
                    width: 37px;
                    height: 15px;
                    opacity: 1;
                    box-sizing: border-box;
                    border: 1px solid #B1B0B0;
                    font-size: 12px;
                    margin-top: 5px;
                    line-height: 15px;
                    margin-left: 5px;
                }

            }
        }

        .copyTemplate {
            width: 257px;
            height: 48px;
            border-radius: 10px;
            background: #549AFE;
            text-align: center;
            line-height: 48px;
            color: #fff;
            font-size: 20px;
            margin-top: 20px;
            cursor: pointer;
            /*悬停变小手的属性*/
        }
    }

    .textarea {
        width: 654px;
        height: 260px;
        border: 1px solid #CCCACA;
        // display: flex;
        // justify-content: center;

        /deep/.el-textarea__inner {
            width: 644px;
            height: 210px;
            resize: none;
            border: none;

        }

        .interposition {
            display: flex;
            justify-content: right;
            margin-right: 15px;

            .interposition_box {
                cursor: pointer;
                /*悬停变小手的属性*/
                width: 34px;
                height: 34px;
                border-radius: 50px;
                background-color: #279BEE;
                display: flex;
                justify-content: center;
                margin-left: 10px;
                line-height: 45px;

                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

/deep/.el-dialog__header{
    border: 1px solid res;
}

// .audioPopup/deep/.el-dialog__wrapper{
//     z-index: 2100 !important;
// }</style>